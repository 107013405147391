export const SET_ADMINS_LIST2STATE = 'CTT/SET_ADMINS_LIST2STATE';
export const SET_ADMIN_DETAILS2STATE = 'CTT/SET_ADMIN_DETAIL2STATE';
export const CLEAR_ADMIN_DETAILS2STATE = 'CTT/CLEAR_ADMIN_DETAIL2STATE';

// Admin Remote API
export const CREATE_ADMIN_USER = 'CTT/CREATE_ADMIN_USER';
export const GET_ADMINS_LIST = 'CTT/GET_ADMINS_LIST ';
export const GET_ADMIN_DETAILS = 'CTT/GET_ADMINS_DETAILS';
export const UPDATE_ADMIN_DETAILS = 'CTT/UPDATE_ADMINS_DETAILS';
export const DELETE_ADMINS_LIST = 'CTT/DELETE_ADMINS_LIST';
export const DELETE_ADMIN_BY_ID = 'CTT/DELETE_ADMIN_BY_ID';
export const UPDATE_ADMIN_STATUS = 'CTT/UPDATE_ADMIN_STATUS';
export const UPDATE_ADMINS_STATUS = 'CTT/UPDATE_ADMINS_STATUS';
export const UPDATE_CONTRACTOR_STATUS = 'CTT/UPDATE_CONTRACTOR_STATUS';

export const GET_CONTRACTOR_ADMIN_DETAILS = 'CTT/GET_CONTRACTOR_ADMIN_DETAILS';
export const SET_CONTRACTOR_ADMIN_DETAILS2STATE = 'CTT/SET_CONTRACTOR_ADMIN_DETAILS2STATE';
export const UPDATE_CONTRACTOR_ADMIN_DETAILS = 'CTT/UPDATE_CONTRACTOR_ADMIN_DETAILS';
export const CLEAR_CONTRACTOR_ADMIN_DETAILS2STATE = 'CTT/CLEAR_CONTRACTOR_ADMIN_DETAILS2STATE';

export const UPDATE_BANK_INFORMATION = 'CTT/UPDATE_BANK_INFORAMTION';
export const UPDATE_PAYMENT_INFORMATION = 'CTT/UPDATE_PAYMENT_INFORMATION';

export const UNMOUNT_CLEAR_ADMIN_DETAILS = 'CTT/UNMOUNT_CLEAR_ADMIN_DETAILS';

