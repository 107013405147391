export const UserTypeDefs = {
  admin: {
    label: 'handel: Admin',
    url: 'admin',
    name: 'admin',
    uiLabel: 'handel: Admin',
  },
  residentialCustomer: {
    label: 'Residential Customer',
    url: 'res-customers',
    name: 'residentialCustomer',
    uiLabel: 'Customer - Residential',
  },
  businessCustomer: {
    label: 'Business Customer',
    url: 'bus-customers',
    name: 'businessCustomer',
    uiLabel: 'Business Customer',
  },
  contractor: {
    label: 'Business Contractor',
    url: 'contractor',
    name: 'contractor',
    uiLabel: 'Business Contractor',
  },
  driver: {
    label: 'Drivers',
    url: 'driver',
    name: 'driver',
    uiLabel: 'Driver',
  },
  councilOfficer: {
    label: 'Council Officer',
    url: 'council-officer',
    name: 'councilOfficer',
    uiLabel: 'Council Officer',
  },
};

export const DiscountTypeDefs = [
  'Percentage',
  'Exact Amount',
  'Extra',
];

export const timeSet = [
  {
    text: '12:00 AM',
    value: '12:00 AM',
    selected: false,
    index: 0,
  },
  {
    text: '12:15 AM',
    value: '12:15 AM',
    selected: false,
    index: 1,
  },
  {
    text: '12:30 AM',
    value: '12:30 AM',
    selected: false,
    index: 2,
  },
  {
    text: '12:45 AM',
    value: '12:45 AM',
    selected: false,
    index: 3,
  },
  {
    text: '01:00 AM',
    value: '01:00 AM',
    selected: false,
    index: 4,
  },
  {
    text: '01:15 AM',
    value: '01:15 AM',
    selected: false,
    index: 5,
  },
  {
    text: '01:30 AM',
    value: '01:30 AM',
    selected: false,
    index: 6,
  },
  {
    text: '01:45 AM',
    value: '01:45 AM',
    selected: false,
    index: 7,
  },
  {
    text: '02:00 AM',
    value: '02:00 AM',
    selected: false,
    index: 8,
  },
  {
    text: '02:15 AM',
    value: '02:15 AM',
    selected: false,
    index: 9,
  },
  {
    text: '02:30 AM',
    value: '02:30 AM',
    selected: false,
    index: 10,
  },
  {
    text: '02:45 AM',
    value: '02:45 AM',
    selected: false,
    index: 11,
  },
  {
    text: '03:00 AM',
    value: '03:00 AM',
    selected: false,
    index: 12,
  },
  {
    text: '03:15 AM',
    value: '03:15 AM',
    selected: false,
    index: 13,
  },
  {
    text: '03:30 AM',
    value: '03:30 AM',
    selected: false,
    index: 14,
  },
  {
    text: '03:45 AM',
    value: '03:45 AM',
    selected: false,
    index: 15,
  },
  {
    text: '04:00 AM',
    value: '04:00 AM',
    selected: false,
    index: 16,
  },
  {
    text: '04:15 AM',
    value: '04:15 AM',
    selected: false,
    index: 17,
  },
  {
    text: '04:30 AM',
    value: '04:30 AM',
    selected: false,
    index: 18,
  },
  {
    text: '04:45 AM',
    value: '04:45 AM',
    selected: false,
    index: 19,
  },
  {
    text: '05:00 AM',
    value: '05:00 AM',
    selected: false,
    index: 20,
  },
  {
    text: '05:15 AM',
    value: '05:15 AM',
    selected: false,
    index: 21,
  },
  {
    text: '05:30 AM',
    value: '05:30 AM',
    selected: false,
    index: 22,
  },
  {
    text: '05:45 AM',
    value: '05:45 AM',
    selected: false,
    index: 23,
  },
  {
    text: '06:00 AM',
    value: '06:00 AM',
    selected: false,
    index: 24,
  },
  {
    text: '06:15 AM',
    value: '06:15 AM',
    selected: false,
    index: 25,
  },
  {
    text: '06:30 AM',
    value: '06:30 AM',
    selected: false,
    index: 26,
  },
  {
    text: '06:45 AM',
    value: '06:45 AM',
    selected: false,
    index: 27,
  },
  {
    text: '07:00 AM',
    value: '07:00 AM',
    selected: false,
    index: 28,
  },
  {
    text: '07:15 AM',
    value: '07:15 AM',
    selected: false,
    index: 29,
  },
  {
    text: '07:30 AM',
    value: '07:30 AM',
    selected: false,
    index: 30,
  },
  {
    text: '07:45 AM',
    value: '07:45 AM',
    selected: false,
    index: 31,
  },
  {
    text: '08:00 AM',
    value: '08:00 AM',
    selected: false,
    index: 32,
  },
  {
    text: '08:15 AM',
    value: '08:15 AM',
    selected: false,
    index: 33,
  },
  {
    text: '08:30 AM',
    value: '08:30 AM',
    selected: false,
    index: 34,
  },
  {
    text: '08:45 AM',
    value: '08:45 AM',
    selected: false,
    index: 35,
  },
  {
    text: '09:00 AM',
    value: '09:00 AM',
    selected: false,
    index: 36,
  },
  {
    text: '09:15 AM',
    value: '09:15 AM',
    selected: false,
    index: 37,
  },
  {
    text: '09:30 AM',
    value: '09:30 AM',
    selected: false,
    index: 38,
  },
  {
    text: '09:45 AM',
    value: '09:45 AM',
    selected: false,
    index: 39,
  },
  {
    text: '10:00 AM',
    value: '10:00 AM',
    selected: false,
    index: 40,
  },
  {
    text: '10:15 AM',
    value: '10:15 AM',
    selected: false,
    index: 41,
  },
  {
    text: '10:30 AM',
    value: '10:30 AM',
    selected: false,
    index: 42,
  },
  {
    text: '10:45 AM',
    value: '10:45 AM',
    selected: false,
    index: 43,
  },
  {
    text: '11:00 AM',
    value: '11:00 AM',
    selected: false,
    index: 44,
  },
  {
    text: '11:15 AM',
    value: '11:15 AM',
    selected: false,
    index: 45,
  },
  {
    text: '11:30 AM',
    value: '11:30 AM',
    selected: false,
    index: 46,
  },
  {
    text: '11:45 AM',
    value: '11:45 AM',
    selected: false,
    index: 47,
  },

  {
    text: '12:00 PM',
    value: '12:00 PM',
    selected: false,
    index: 48,
  },
  {
    text: '12:15 PM',
    value: '12:15 PM',
    selected: false,
    index: 49,
  },
  {
    text: '12:30 PM',
    value: '12:30 PM',
    selected: false,
    index: 50,
  },
  {
    text: '12:45 PM',
    value: '12:45 PM',
    selected: false,
    index: 51,
  },
  {
    text: '01:00 PM',
    value: '01:00 PM',
    selected: false,
    index: 52,
  },
  {
    text: '01:15 PM',
    value: '01:15 PM',
    selected: false,
    index: 53,
  },
  {
    text: '01:30 PM',
    value: '01:30 PM',
    selected: false,
    index: 54,
  },
  {
    text: '01:45 PM',
    value: '01:45 PM',
    selected: false,
    index: 55,
  },
  {
    text: '02:00 PM',
    value: '02:00 PM',
    selected: false,
    index: 56,
  },
  {
    text: '02:15 PM',
    value: '02:15 PM',
    selected: false,
    index: 57,
  },
  {
    text: '02:30 PM',
    value: '02:30 PM',
    selected: false,
    index: 58,
  },
  {
    text: '02:45 PM',
    value: '02:45 PM',
    selected: false,
    index: 59,
  },
  {
    text: '03:00 PM',
    value: '03:00 PM',
    selected: false,
    index: 60,
  },
  {
    text: '03:15 PM',
    value: '03:15 PM',
    selected: false,
    index: 61,
  },
  {
    text: '03:30 PM',
    value: '03:30 PM',
    selected: false,
    index: 62,
  },
  {
    text: '03:45 PM',
    value: '03:45 PM',
    selected: false,
    index: 63,
  },
  {
    text: '04:00 PM',
    value: '04:00 PM',
    selected: false,
    index: 64,
  },
  {
    text: '04:15 PM',
    value: '04:15 PM',
    selected: false,
    index: 65,
  },
  {
    text: '04:30 PM',
    value: '04:30 PM',
    selected: false,
    index: 66,
  },
  {
    text: '04:45 PM',
    value: '04:45 PM',
    selected: false,
    index: 67,
  },
  {
    text: '05:00 PM',
    value: '05:00 PM',
    selected: false,
    index: 68,
  },
  {
    text: '05:15 PM',
    value: '05:15 PM',
    selected: false,
    index: 69,
  },
  {
    text: '05:30 PM',
    value: '05:30 PM',
    selected: false,
    index: 70,
  },
  {
    text: '05:45 PM',
    value: '05:45 PM',
    selected: false,
    index: 71,
  },
  {
    text: '06:00 PM',
    value: '06:00 PM',
    selected: false,
    index: 72,
  },
  {
    text: '06:15 PM',
    value: '06:15 PM',
    selected: false,
    index: 73,
  },
  {
    text: '06:30 PM',
    value: '06:30 PM',
    selected: false,
    index: 74,
  },
  {
    text: '06:45 PM',
    value: '06:45 PM',
    selected: false,
    index: 75,
  },
  {
    text: '07:00 PM',
    value: '07:00 PM',
    selected: false,
    index: 76,
  },
  {
    text: '07:15 PM',
    value: '07:15 PM',
    selected: false,
    index: 77,
  },
  {
    text: '07:30 PM',
    value: '07:30 PM',
    selected: false,
    index: 78,
  },
  {
    text: '07:45 PM',
    value: '07:45 PM',
    selected: false,
    index: 79,
  },
  {
    text: '08:00 PM',
    value: '08:00 PM',
    selected: false,
    index: 80,
  },
  {
    text: '08:15 PM',
    value: '08:15 PM',
    selected: false,
    index: 81,
  },
  {
    text: '08:30 PM',
    value: '08:30 PM',
    selected: false,
    index: 82,
  },
  {
    text: '08:45 PM',
    value: '08:45 PM',
    selected: false,
    index: 83,
  },
  {
    text: '09:00 PM',
    value: '09:00 PM',
    selected: false,
    index: 84,
  },
  {
    text: '09:15 PM',
    value: '09:15 PM',
    selected: false,
    index: 85,
  },
  {
    text: '09:30 PM',
    value: '09:30 PM',
    selected: false,
    index: 86,
  },
  {
    text: '09:45 PM',
    value: '09:45 PM',
    selected: false,
    index: 87,
  },
  {
    text: '10:00 PM',
    value: '10:00 PM',
    selected: false,
    index: 88,
  },
  {
    text: '10:15 PM',
    value: '10:15 PM',
    selected: false,
    index: 89,
  },
  {
    text: '10:30 PM',
    value: '10:30 PM',
    selected: false,
    index: 90,
  },
  {
    text: '10:45 PM',
    value: '10:45 PM',
    selected: false,
    index: 91,
  },
  {
    text: '11:00 PM',
    value: '11:00 PM',
    selected: false,
    index: 92,
  },
  {
    text: '11:15 PM',
    value: '11:15 PM',
    selected: false,
    index: 93,
  },
  {
    text: '11:30 PM',
    value: '11:30 PM',
    selected: false,
    index: 94,
  },
  {
    text: '11:45 PM',
    value: '11:45 PM',
    selected: false,
    index: 95,
  },

];
