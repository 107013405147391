export const SET_PRODUCT_REQUESTS_LIST2STATE = 'ADM/SET_PRODUCT_REQUESTS_LIST2STATE';
export const SET_PRODUCT_REQUESTS_DETAIL2STATE = 'ADM/SET_PRODUCT_REQUESTS_DETAIL2STATE';
export const CLEAR_PRODUCT_REQUESTS_DETAIL2STATE = 'ADM/CLEAR_PRODUCT_REQUESTS_DETAIL2STATE';
export const PUT_PRODUCT_REQUESTS_DETAIL2STATE = 'ADM/PUT_PRODUCT_REQUESTS_DETAIL2STATE';

// Admin Remote API
export const GET_PRODUCT_REQUESTS_LIST = 'ADM/GET_PRODUCT_REQUESTS_LIST ';
export const GET_PRODUCT_REQUESTS_DETAILS_BY_ID = 'ADM/GET_PRODUCT_REQUESTS_DETAILS_BY_ID';
export const UPDATE_PRODUCT_REQUESTS_DETAILS = 'ADM/UPDATE_PRODUCT_REQUESTS_DETAILS';
export const DELETE_PRODUCT_REQUESTS_LIST = 'ADM/DELETE_PRODUCT_REQUESTS_LIST';
export const DELETE_PRODUCT_REQUESTS_BY_ID = 'ADM/DELETE_PRODUCT_REQUESTS_BY_ID';
export const UPDATE_PRODUCT_REQUESTS_STATUS = 'ADM/UPDATE_PRODUCT_REQUESTS_STATUS';
export const UPDATE_PRODUCT_REQUESTS_DELIVERY_STATUS = 'ADM/UPDATE_PRODUCT_REQUESTS_DELIVERY_STATUS';

export const UNMOUNT_CLEAR_PRODUCT_REQUESTS_DETAILS = 'ADM/UNMOUNT_CLEAR_PRODUCT_REQUESTS_DETAILS';

export const CHANGE_CURRENT_TAB = 'ADM/CHANGE_CURRENT_TAB';
export const CHANGE_PER_PAGE = 'ADM/CHANGE_PER_PAGE';
export const CHANGE_CURRENT_PAGE = 'ADM/CHANGE_CURRENT_PAGE';
export const UPDATE_SEARCH_VALUE = 'ADM/UPDATE_SEARCH_VALUE';
export const UPDATE_LOCATION_KEY = 'ADM/UPDATE_LOCATION_KEY';
export const CHANGE_VIEW_MODE = 'ADM/CHANGE_VIEW_MODE';
export const UPDATE_FILTER_STATE = 'ADM/UPDATE_FILTER_STATE';

// Create product request
export const UPDATE_PRODUCT_REQUEST_DRAFT = 'ADM/UPDATE_PRODUCT_REQUEST_DRAFT';
export const UPDATE_PRODUCT_REQUEST_DRAFT_SUCCESS = 'ADM/UPDATE_PRODUCT_REQUEST_DRAFT_SUCCESS';
export const CREATE_PRODUCT_REQUEST = 'ADM/CREATE_PRODUCT_REQUEST';
export const CREATE_PRODUCT_REQUEST_SUCCESS = 'ADM/CREATE_PRODUCT_REQUEST_SUCCESS';
export const CREATE_PRODUCT_REQUEST_FAILURE = 'ADM/CREATE_PRODUCT_REQUEST_FAILURE';

/**
 * Import actions
 */
export const IMPORT_PRODUCT_ORDER = 'ADM/IMPORT_PRODUCT_ORDER';
export const IMPORT_PRODUCT_ORDER_REQUESTING = 'ADM/IMPORT_PRODUCT_ORDER_REQUESTING';
export const IMPORT_PRODUCT_ORDER_SUCCESSED = 'ADM/IMPORT_PRODUCT_ORDER_SUCCESSED';
export const IMPORT_PRODUCT_ORDER_FAILED = 'ADM/IMPORT_PRODUCT_ORDER_FAILED';

/**
 * Download QR Code
 */
export const DOWNLOAD_QR_CODE = 'ADM/DOWNLOAD_QR_CODE';
export const DOWNLOAD_QR_CODE_REQUESTING = 'ADM/DOWNLOAD_QR_CODE_REQUESTING';
export const DOWNLOAD_QR_CODE_SUCCESSED = 'ADM/DOWNLOAD_QR_CODE_SUCCESSED';
export const DOWNLOAD_QR_CODE_FAILED = 'ADM/DOWNLOAD_QR_CODE_FAILED';

/**
 * Bin request notes
 */
export const ADD_PRODUCT_REQUEST_NOTE = 'ADM/PRODUCT_REQUEST/ADD_PRODUCT_REQUEST_NOTE';
export const FETCH_PRODUCT_REQUEST_NOTES = 'ADM/PRODUCT_REQUEST/FETCH_PRODUCT_REQUEST_NOTES';
export const SET_PRODUCT_REQUEST_NOTE_STATE = 'ADM/PRODUCT_REQUEST/SET_PRODUCT_REQUEST_NOTE_STATE';
export const DELETE_PRODUCT_REQUEST_NOTE = 'ADM/PRODUCT_REQUEST/DELETE_PRODUCT_REQUEST_NOTE';
export const UPDATE_PRODUCT_REQUEST_NOTE = 'ADM/PRODUCT_REQUEST/UPDATE_PRODUCT_REQUEST_NOTE';
