export const SET_CUSTOMERS_LIST2STATE = 'CTT/SET_CUSTOMERS_LIST2STATE';
export const SET_CUSTOMER_DETAILS2STATE = 'CTT/SET_CUSTOMER_DETAIL2STATE';
export const CLEAR_CUSTOMER_DETAILS2STATE = 'CTT/CLEAR_CUSTOMER_DETAIL2STATE';

// Admin Remote API
export const GET_CUSTOMERS_LIST = 'CTT/GET_CUSTOMERS_LIST ';
export const GET_CUSTOMER_DETAILS_BY_ID = 'CTT/GET_CUSTOMERS_DETAILS_BY_ID';
export const UPDATE_CUSTOMER_DETAILS = 'CTT/UPDATE_CUSTOMERS_DETAILS';
export const DELETE_CUSTOMERS_LIST = 'CTT/DELETE_CUSTOMERS_LIST';
export const DELETE_CUSTOMER_BY_ID = 'CTT/DELETE_CUSTOMER_BY_ID';
export const UPDATE_CUSTOMER_STATUS = 'CTT/UPDATE_CUSTOMER_STATUS';
export const UPDATE_CUSTOMERS_STATUS = 'CTT/UPDATE_CUSTOMERS_STATUS';

export const UNMOUNT_CLEAR_CUSTOMER_DETAILS = 'CTT/UNMOUNT_CLEAR_CUSTOMER_DETAILS';

export const ADM_RESET_CUSTOMER_PASSWORD = 'CTT/RESET_CUSTOMER_PASSWORD';

export const GET_DRIVER_RATINGS_BY_ID = 'CTT/GET_DRIVER_RATINGS_BY_ID';
export const SET_DRIVER_RATINGS2STATE = 'CTT/SET_DRIVER_RATINGS2STATE';

export const CREATE_DRIVER = 'CTT/CREATE_DRIVER';

export const GET_UNASSIGNED_JOBS_LIST = 'CTT/GET_UNASSIGNED_JOBS_LIST';
export const SET_UNASSIGNED_JOBS_LIST2STATE = 'CTT/SET_UNASSIGNED_JOBS_LIST2STATE';
export const ASSIGN_JOB_2DRIVER = 'CTT/ASSIGN_JOB_2DRIVER';
