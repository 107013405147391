export const SET_PRODUCTS_LIST2STATE = 'ADM/SET_PRODUCTS_LIST2STATE';
export const SET_PRODUCT_DETAIL2STATE = 'ADM/SET_PRODUCT_DETAIL2STATE';
export const CLEAR_CUSTOMER_DETAIL2STATE = 'ADM/CLEAR_PRODUCT_DETAIL2STATE';

export const PUT_PRODUCT_DETAIL2STATE = 'ADM/PUT_PRODUCT_DETAIL2STATE';

// Admin Remote API
export const GET_PRODUCTS_LIST = 'ADM/GET_PRODUCTS_LIST ';
export const GET_PRODUCT_DETAILS_BY_ID = 'ADM/GET_PRODUCTS_DETAILS_BY_ID';
export const UPDATE_PRODUCT_DETAILS = 'ADM/UPDATE_PRODUCTS_DETAILS';
export const DELETE_PRODUCTS_LIST = 'ADM/DELETE_PRODUCTS_LIST';
export const DELETE_PRODUCT_BY_ID = 'ADM/DELETE_PRODUCT_BY_ID';
export const UPDATE_PRODUCT_STATUS = 'ADM/UPDATE_PRODUCT_STATUS';

export const CREATE_PRODUCT = 'ADM/CREATE_PRODUCT';

export const UNMOUNT_CLEAR_PRODUCT_DETAILS = 'ADM/UNMOUNT_CLEAR_PRODUCT_DETAILS';

export const GET_PRODUCT_MATERIAL_OPTIONS = 'ADM/GET_PRODUCT_MATERIAL_OPTIONS';
export const PUT_PRODUCT_MATERIAL_OPTIONS2STATE = 'PUT_PRODUCT_MATERIAL_OPTIONS2STATE';

export const GET_PRODUCT_COUNCILS_DEFINATIONS = 'ADM/GET_PRODUCT_COUNCILS_DEFINATIONS';
export const PUT_PRODUCT_COUNCILS_DEFINATIONS = 'ADM/PUT_PRODUCT_COUNCILS_DEFINATIONS';

export const GET_PRODUCT_WASTETYPES_LIST = 'ADM/GET_PRODUCT_WASTETYPES_LIST ';
export const SET_PRODUCT_WASTETYPES_LIST2STATE = 'ADM/SET_PRODUCT_WASTETYPES_LIST2STATE';

export const CREATE_COUNCIL_PRODUCT = 'ADM/CREATE_COUNCIL_PRODUCT';
export const GET_COUNCIL_PRODUCT_LIST = 'ADM/GET_COUNCIL_PRODUCT_LIST';
export const PUT_COUNCIL_PRODUCT_LIST2STATE = 'ADM/PUT_COUNCIL_PRODUCT_LIST2STATE';
export const UPDATE_COUNCIL_PRODUCT_STATUS_BY_ID = 'ADM/UPDATE_COUNCIL_PRODUCT_STATUS_BY_ID';
export const DELETE_COUNCIL_PRODUCT_BY_ID = 'ADM/DELETE_COUNCIL_PRODUCT_BY_ID';

export const GET_COUNCIL_PRODUCT_DETAILS_BY_ID = 'ADM/GET_COUNCIL_PRODUCT_DETAILS_BY_ID';
export const PUT_COUNCIL_PRODUCT_DETAILS2STATE = 'ADM/PUT_COUNCIL_PRODUCT_DETAILS2STATE';
export const UPDATE_COUNCIL_PRODUCT_DETAILS_BY_ID = 'ADM/UPDATE_COUNCIL_PRODUCT_DETAILS_BY_ID';

export const FETCH_ALL_PRODUCTS = 'ADM/PRODUCTS/FETCH_ALL_PRODUCTS';
export const SET_PRODUCT_OPTIONS = 'ADM/PRODUCTS/SET_PRODUCT_OPTIONS';
