export const SET_DISCOUNTS_LIST2STATE = 'ADM/SET_DISCOUNTS_LIST2STATE';
export const SET_DISCOUNT_DETAIL2STATE = 'ADM/SET_DISCOUNT_DETAIL2STATE';
export const CLEAR_CUSTOMER_DETAIL2STATE = 'ADM/CLEAR_DISCOUNT_DETAIL2STATE';

export const PUT_DISCOUNT_DETAIL2STATE = 'ADM/PUT_DISCOUNT_DETAIL2STATE';

// Admin Remote API
export const GET_DISCOUNTS_LIST = 'ADM/GET_DISCOUNTS_LIST ';
export const GET_DISCOUNT_DETAILS_BY_ID = 'ADM/GET_DISCOUNTS_DETAILS_BY_ID';
export const UPDATE_DISCOUNT_DETAILS = 'ADM/UPDATE_DISCOUNTS_DETAILS';
export const DELETE_DISCOUNTS_LIST = 'ADM/DELETE_DISCOUNTS_LIST';
export const DELETE_DISCOUNT_BY_ID = 'ADM/DELETE_DISCOUNT_BY_ID';
export const UPDATE_DISCOUNT_STATUS = 'ADM/UPDATE_DISCOUNT_STATUS';

export const CREATE_DISCOUNT = 'ADM/CREATE_DISCOUNT';

export const UNMOUNT_CLEAR_DISCOUNT_DETAILS = 'ADM/UNMOUNT_CLEAR_DISCOUNT_DETAILS';

export const GET_DISCOUNT_REGIONS_DEFINATIONS = 'ADM/GET_DISCOUNT_REGIONS_DEFINATIONS';
export const PUT_DISCOUNT_REGIONS_DEFINATIONS = 'ADM/PUT_DISCOUNT_REGIONS_DEFINATIONS';

export const GET_DISCOUNT_PRODUCTS_LIST = 'ADM/GET_DISCOUNT_PRODUCTS_LIST ';
export const SET_DISCOUNT_PRODUCTS_LIST2STATE = 'ADM/SET_DISCOUNT_PRODUCTS_LIST2STATE';
