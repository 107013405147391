import createHistory from 'history/createBrowserHistory';
import { routerMiddleware } from 'react-router-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { loadIdentity } from './common/actions';
import reducers from './reducers';
import rootSaga from './sagas';

// store.js
const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);


// enable redux devtool chrome extension
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// add the react-router-redux reducer to store on the `router` key
// also apply our middleware for navigating
export const history = createHistory();

// add redux-saga middleware
const sagaMiddleware = createSagaMiddleware();

// create the store
const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(
    routerMiddleware(history),
    sagaMiddleware,
  )),
);

// run saga
sagaMiddleware.run(rootSaga, store.dispatch, store.getState);

// load data from local storage to store on initializing
store.dispatch(loadIdentity());

const persistor = persistStore(store);

export {
  store,
  persistor,
};
