
export const ProductRequestsDefs = {
  productAdmin: {
    label: 'Product Requests',
    url: 'bin-requests',
    name: 'productRequests',
  },
};

export const userTypes = [
  'Residential Customer',
  'Business Customer',
];

