export const SET_CUSTOMERS_LIST2STATE = 'ADM/SET_CUSTOMERS_LIST2STATE';
export const SET_CUSTOMER_DETAILS2STATE = 'ADM/SET_CUSTOMER_DETAIL2STATE';
export const CLEAR_CUSTOMER_DETAILS2STATE = 'ADM/CLEAR_CUSTOMER_DETAIL2STATE';

// Admin Remote API
export const GET_CUSTOMERS_LIST = 'ADM/GET_CUSTOMERS_LIST ';
export const GET_CUSTOMER_DETAILS_BY_ID = 'ADM/GET_CUSTOMERS_DETAILS_BY_ID';
export const UPDATE_CUSTOMER_DETAILS = 'ADM/UPDATE_CUSTOMERS_DETAILS';
export const DELETE_CUSTOMERS_LIST = 'ADM/DELETE_CUSTOMERS_LIST';
export const DELETE_CUSTOMER_BY_ID = 'ADM/DELETE_CUSTOMER_BY_ID';
export const UPDATE_CUSTOMER_STATUS = 'ADM/UPDATE_CUSTOMER_STATUS';
export const UPDATE_CUSTOMERS_STATUS = 'ADM/UPDATE_CUSTOMERS_STATUS';
export const ADD_NEW_CUSTOMER = 'ADM/ADD_NEW_CUSTOMER';

export const GET_COUNCIL_LIST = 'ADM/GET_COUNCIL_LIST';
export const SET_COUNCIL_LIST2STATE = 'ADM/SET_COUNCIL_LIST2STATE';


export const UNMOUNT_CLEAR_CUSTOMER_DETAILS = 'ADM/UNMOUNT_CLEAR_CUSTOMER_DETAILS';

export const ADM_RESET_CUSTOMER_PASSWORD = 'ADM/RESET_CUSTOMER_PASSWORD';

export const SET_CONTRACTOR_LIST2STATE = 'ADM/SET_CONTRACTOR_LIST2STATE';

export const SET_TAB = 'ADM/SET_TAB';

export const GET_USER_TRANSACTION_HISTORY = 'ADM/GET_USER_TRANSACTION_HISTORY';

export const SET_USER_TRANSACTION_HISTORY_LOADING = 'ADM/SET_USER_TRANSACTION_HISTORY_LOADING';
export const SET_USER_TRANSACTION_HISTORY = 'ADM/SET_USER_TRANSACTION_HISTORY';



export const CREATE_NEW_CONNECTED_USER = 'ADM/CREATE_NEW_CONNECTED_USER';
export const CREATE_NEW_CONNECTED_USER_START = 'ADM/CREATE_NEW_CONNECTED_USER_START';
export const CREATE_NEW_CONNECTED_USER_COMPLETED = 'ADM/CREATE_NEW_CONNECTED_USER_COMPLETED';
export const CREATE_NEW_CONNECTED_USER_FAILED = 'ADM/CREATE_NEW_CONNECTED_USER_FAILED';
export const CLEAR_CREATE_NEW_CONNECTED_USER = 'ADM/CLEAR_CREATE_NEW_CONNECTED_USER';
