
export const DumpsiteDefs = {
  dumpsiteAdmin: {
    label: 'All Dumpsites',
    url: 'dumpsites',
    name: 'dumpsites',
  },
};

export const WeekDayDefs = [
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
  'Sun',
];
