export const SET_DUMPSITES_LIST2STATE = 'ADM/SET_DUMPSITES_LIST2STATE';
export const SET_DUMPSITE_DETAIL2STATE = 'ADM/SET_DUMPSITE_DETAIL2STATE';
export const CLEAR_DUMPSITE_DETAIL2STATE = 'ADM/CLEAR_DUMPSITE_DETAIL2STATE';

export const PUT_DUMPSITE_DETAIL2STATE = 'ADM/PUT_DUMPSITE_DETAIL2STATE';

// Admin Remote API
export const GET_DUMPSITES_LIST = 'ADM/GET_DUMPSITES_LIST ';
export const GET_DUMPSITE_DETAILS_BY_ID = 'ADM/GET_DUMPSITES_DETAILS_BY_ID';
export const UPDATE_DUMPSITE_DETAILS = 'ADM/UPDATE_DUMPSITES_DETAILS';
export const DELETE_DUMPSITES_LIST = 'ADM/DELETE_DUMPSITES_LIST';
export const DELETE_DUMPSITE_BY_ID = 'ADM/DELETE_DUMPSITE_BY_ID';
export const UPDATE_DUMPSITE_STATUS = 'ADM/UPDATE_DUMPSITE_STATUS';

export const CREATE_DUMPSITE = 'ADM/CREATE_DUMPSITE';

export const UNMOUNT_CLEAR_DUMPSITE_DETAILS = 'ADM/UNMOUNT_CLEAR_DUMPSITE_DETAILS';

export const GET_DUMPSITE_COUNCILS_DEFINATIONS = 'ADM/GET_DUMPSITE_COUNCILS_DEFINATIONS';
export const PUT_DUMPSITE_COUNCILS_DEFINATIONS = 'ADM/PUT_DUMPSITE_COUNCILS_DEFINATIONS';

export const GET_DUMPSITE_WASTETYPES_LIST = 'ADM/GET_DUMPSITE_WASTETYPES_LIST ';
export const SET_DUMPSITE_WASTETYPES_LIST2STATE = 'ADM/SET_DUMPSITE_WASTETYPES_LIST2STATE';
