export const SET_COUNCILS_LIST2STATE = 'ADM/SET_COUNCILS_LIST2STATE';
export const SET_COUNCIL_DETAIL2STATE = 'ADM/SET_COUNCIL_DETAIL2STATE';
export const CLEAR_COUNCIL_DETAIL2STATE = 'ADM/CLEAR_COUNCIL_DETAIL2STATE';

export const PUT_COUNCIL_DETAIL2STATE = 'ADM/PUT_COUNCIL_DETAIL2STATE';

// Admin Remote API
export const GET_COUNCILS_LIST = 'ADM/GET_COUNCILS_LIST ';
export const GET_COUNCIL_DETAILS_BY_ID = 'ADM/GET_COUNCILS_DETAILS_BY_ID';
export const UPDATE_COUNCIL_DETAILS = 'ADM/UPDATE_COUNCILS_DETAILS';
export const DELETE_COUNCILS_LIST = 'ADM/DELETE_COUNCILS_LIST';
export const DELETE_COUNCIL_BY_ID = 'ADM/DELETE_COUNCIL_BY_ID';
export const UPDATE_COUNCIL_STATUS = 'ADM/UPDATE_COUNCIL_STATUS';

export const CREATE_COUNCIL = 'ADM/CREATE_COUNCIL';

export const GET_COUNCIL_REGIONS_DEFINATIONS =
  'ADM/GET_COUNCIL_REGIONS_DEFINATIONS';
export const PUT_COUNCIL_REGIONS_DEFINATIONS2STATE =
  'ADM/PUT_COUNCIL_REGIONS_DEFINATIONS2STATE';

export const GET_COUNCIL_STATES_DEFINATIONS =
  'ADM/GET_COUNCIL_STATES_DEFINATIONS';
export const PUT_COUNCIL_STATES_DEFINATIONS2STATE =
  'ADM/PUT_COUNCIL_STATES_DEFINATIONS2STATE';

export const UNMOUNT_CLEAR_COUNCIL_DETAILS = 'ADM/UNMOUNT_CLEAR_COUNCIL_DETAILS';

export const FETCH_ALL_COUNCILS = 'ADM/PRODUCTS/FETCH_ALL_COUNCIL';
export const SET_COUNCIL_OPTIONS = 'ADM/PRODUCTS/SET_COUNCIL_OPTIONS';
