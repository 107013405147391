
export const GET_DASHBOARD_SUMMARY = 'ADM/GET_DASHBOARD_SUMMARY';
export const PUT_DASHBOAD_SUMMARY2STATE = 'ADM/PUT_DASHBOARD_SUMMARY2STATE';
export const GET_DASHBOARD_REVENUE = 'ADM/GET_DASHBOARD_REVENUE';
export const PUT_DASHBOARD_REVENUE2STATE = 'ADM/PUT_DASHBOARD_REVENUE2STATE';
export const GET_DASHBOARD_RATE = 'ADM/GET_DASHBOARD_RATE';
export const PUT_DASHBOARD_RATE2STATE = 'ADM/PUT_DASHBOARD_RATE2STATE';

export const GET_DASHBOARD_AVERAGETIME = 'ADM/GET_DASHBOARD_AVAERAGETIME';
export const PUT_DASHBOARD_AVERATETIME2STATE = 'ADM/PUT_DASHBOARD_AVERAGETIME2STATE';

export const GET_DASHBOARD_JOBS = 'ADM/GET_DASHBOARD_JOBS';
export const PUT_DASHBOARD_JOBS2STATE = 'ADM/PUT_DASHBOARD_JOBS2STATE';
export const MANUALLY_DASHBOARD_JOB_UPDATE = 'ADM/MANUALLY_DASHBOARD_JOB_UPDATE';

export const GET_DASHBOARD_RISK = 'ADM/GET_DASHBOARD_RISK';
export const PUT_DASHBOARD_RISK2STATE = 'ADM/PUT_DASHBOARD_RISK2STATE';

export const GET_DASHBOARD_INACTIVE = 'ADM/GET_DASHBORD_INACTIVE';
export const PUT_DASHBOARD_INACTIVE2STATE = 'ADM/PUT_DASHBOARD_INACTIVE2STATE';

export const GET_DASHBOARD_DRIVERLIST_BY_JOBID = 'ADM/GET_DASHBOARD_DRIVERLIST_BY_JOBID';
export const PUT_DASHBOARD_DRIVERLIST_BY_JOBID = 'ADM/PUT_DASHBOARD_DRIVERLIST_BY_JOBID';
export const SET_DASHBOARD_JOB_DRIVER = 'ADM/SET_DASHBOARD_JOB_DRIVER';
export const EXPORT_TO_GOOGLESHEET = 'ADM/EXPORT_TO_GOOGLESHEET';
