export const SET_MANAGE_TRANSACTIONS_LIST2STATE = 'CTT/SET_MANAGE_TRANSACTIONS_LIST2STATE';
export const SET_MANAGE_TRANSACTIONS_DETAIL2STATE = 'CTT/SET_MANAGE_TRANSACTIONS_DETAIL2STATE';
export const CLEAR_MANAGE_TRANSACTIONS_DETAIL2STATE = 'CTT/CLEAR_MANAGE_TRANSACTIONS_DETAIL2STATE';
export const PUT_MANAGE_TRANSACTIONS_DETAIL2STATE = 'CTT/PUT_MANAGE_TRANSACTIONS_DETAIL2STATE';

// Admin Remote API
export const GET_MANAGE_TRANSACTIONS_LIST = 'CTT/GET_MANAGE_TRANSACTIONS_LIST ';
export const GET_MANAGE_TRANSACTIONS_DETAILS_BY_ID = 'CTT/GET_MANAGE_TRANSACTIONS_DETAILS_BY_ID';
export const UPDATE_MANAGE_TRANSACTIONS_DETAILS = 'CTT/UPDATE_MANAGE_TRANSACTIONS_DETAILS';
export const DELETE_MANAGE_TRANSACTIONS_LIST = 'CTT/DELETE_MANAGE_TRANSACTIONS_LIST';
export const DELETE_MANAGE_TRANSACTIONS_BY_ID = 'CTT/DELETE_MANAGE_TRANSACTIONS_BY_ID';
export const UPDATE_MANAGE_TRANSACTIONS_STATUS = 'CTT/UPDATE_MANAGE_TRANSACTIONS_STATUS';

export const UNMOUNT_CLEAR_MANAGE_TRANSACTIONS_DETAILS = 'CTT/UNMOUNT_CLEAR_MANAGE_TRANSACTIONS_DETAILS';

